/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-discord': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<path pid="0" d="M13.36 9.975c0 .64-.507 1.165-1.15 1.165-.63 0-1.149-.524-1.149-1.165 0-.64.507-1.165 1.15-1.165.642 0 1.15.524 1.15 1.165zM8.096 8.81c-.642 0-1.15.524-1.15 1.165 0 .64.52 1.165 1.15 1.165.643 0 1.15-.524 1.15-1.165a1.148 1.148 0 00-1.15-1.165zM20 2.162V21c-2.84-2.338-1.932-1.564-5.23-4.42l.597 1.942H2.584c-1.274 0-2.31-.966-2.31-2.163V2.163C.273.966 1.31 0 2.583 0H17.69c1.274 0 2.31.966 2.31 2.163zm-3.213 9.954c0-3.381-1.623-6.122-1.623-6.122-1.623-1.133-3.167-1.102-3.167-1.102l-.158.168c1.916.546 2.806 1.333 2.806 1.333-2.677-1.367-5.822-1.367-8.42-.304-.417.179-.665.304-.665.304s.936-.829 2.965-1.375l-.113-.126s-1.544-.031-3.168 1.103c0 0-1.623 2.74-1.623 6.121 0 0 .947 1.522 3.438 1.596 0 0 .417-.472.756-.871-1.432-.4-1.973-1.24-1.973-1.24.166.109.44.249.462.263 1.903.992 4.605 1.318 7.034.367a6.643 6.643 0 001.296-.619s-.563.861-2.04 1.25c.338.399.744.85.744.85 2.491-.073 3.45-1.596 3.45-1.596z" _fill="#fff" fill-opacity=".4"/>'
  }
})
